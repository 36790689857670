.swal2-popup.swal2-toast {
  box-sizing: border-box;
  grid-column: 1/4 !important;
  grid-row: 1/4 !important;
  grid-template-columns: 1fr 99fr 1fr;
  padding: 1em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075), 1px 2px 4px rgba(0, 0, 0, 0.075), 1px 3px 8px rgba(0, 0, 0, 0.075), 2px 4px 16px rgba(0, 0, 0, 0.075);
  pointer-events: all; }

.swal2-popup.swal2-toast > * {
  grid-column: 2; }

.swal2-popup.swal2-toast .swal2-title {
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial; }

.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center; }

.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: .5em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-footer {
  margin: .5em 0 0;
  padding: .5em 0 0;
  font-size: .8em; }

.swal2-popup.swal2-toast .swal2-close {
  grid-column: 3/3;
  grid-row: 1/99;
  align-self: center;
  width: .8em;
  height: .8em;
  margin: 0;
  font-size: 2em; }

.swal2-popup.swal2-toast .swal2-html-container {
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial; }

.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0; }

.swal2-popup.swal2-toast .swal2-loader {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  height: 2em;
  margin: .25em; }

.swal2-popup.swal2-toast .swal2-icon {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 .5em 0 0; }

.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: 700; }

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: .875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: .3125em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: .3125em; }

.swal2-popup.swal2-toast .swal2-actions {
  justify-content: flex-start;
  height: auto;
  margin: 0;
  margin-top: .5em;
  padding: 0 .5em; }

.swal2-popup.swal2-toast .swal2-styled {
  margin: .25em .5em;
  padding: .4em .6em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.8em;
  left: -.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.25em;
  left: .9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: .4375em;
  width: .4375em;
  height: 2.6875em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: .3125em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: .1875em;
  width: .75em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: .9375em;
  right: .1875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-toast-animate-success-line-tip .75s;
  animation: swal2-toast-animate-success-line-tip .75s; }

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-toast-animate-success-line-long .75s;
  animation: swal2-toast-animate-success-line-long .75s; }

.swal2-popup.swal2-toast.swal2-show {
  -webkit-animation: swal2-toast-show .5s;
  animation: swal2-toast-show .5s; }

.swal2-popup.swal2-toast.swal2-hide {
  -webkit-animation: swal2-toast-hide .1s forwards;
  animation: swal2-toast-hide .1s forwards; }

.swal2-container {
  display: grid;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto);
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
  height: 100%;
  padding: .625em;
  overflow-x: hidden;
  transition: background-color .1s;
  -webkit-overflow-scrolling: touch; }

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4); }

.swal2-container.swal2-backdrop-hide {
  background: 0 0 !important; }

.swal2-container.swal2-bottom-start, .swal2-container.swal2-center-start, .swal2-container.swal2-top-start {
  grid-template-columns: minmax(0, 1fr) auto auto; }

.swal2-container.swal2-bottom, .swal2-container.swal2-center, .swal2-container.swal2-top {
  grid-template-columns: auto minmax(0, 1fr) auto; }

.swal2-container.swal2-bottom-end, .swal2-container.swal2-center-end, .swal2-container.swal2-top-end {
  grid-template-columns: auto auto minmax(0, 1fr); }

.swal2-container.swal2-top-start > .swal2-popup {
  align-self: start; }

.swal2-container.swal2-top > .swal2-popup {
  grid-column: 2;
  align-self: start;
  justify-self: center; }

.swal2-container.swal2-top-end > .swal2-popup, .swal2-container.swal2-top-right > .swal2-popup {
  grid-column: 3;
  align-self: start;
  justify-self: end; }

.swal2-container.swal2-center-left > .swal2-popup, .swal2-container.swal2-center-start > .swal2-popup {
  grid-row: 2;
  align-self: center; }

.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center; }

.swal2-container.swal2-center-end > .swal2-popup, .swal2-container.swal2-center-right > .swal2-popup {
  grid-column: 3;
  grid-row: 2;
  align-self: center;
  justify-self: end; }

.swal2-container.swal2-bottom-left > .swal2-popup, .swal2-container.swal2-bottom-start > .swal2-popup {
  grid-column: 1;
  grid-row: 3;
  align-self: end; }

.swal2-container.swal2-bottom > .swal2-popup {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
  align-self: end; }

.swal2-container.swal2-bottom-end > .swal2-popup, .swal2-container.swal2-bottom-right > .swal2-popup {
  grid-column: 3;
  grid-row: 3;
  align-self: end;
  justify-self: end; }

.swal2-container.swal2-grow-fullscreen > .swal2-popup, .swal2-container.swal2-grow-row > .swal2-popup {
  grid-column: 1/4;
  width: 100%; }

.swal2-container.swal2-grow-column > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-row: 1/4;
  align-self: stretch; }

.swal2-container.swal2-no-transition {
  transition: none !important; }

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem; }

.swal2-popup:focus {
  outline: 0; }

.swal2-popup.swal2-loading {
  overflow-y: hidden; }

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: .8em 1em 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word; }

.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 1.25em auto 0;
  padding: 0; }

.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4; }

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); }

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }

.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: .25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 transparent #2778c4 transparent; }

.swal2-styled {
  margin: .3125em;
  padding: .625em 1.1em;
  transition: box-shadow .1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500; }

.swal2-styled:not([disabled]) {
  cursor: pointer; }

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(112, 102, 224, 0.5); }

.swal2-styled.swal2-deny {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #dc3741;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-deny:focus {
  box-shadow: 0 0 0 3px rgba(220, 55, 65, 0.5); }

.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #6e7881;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px rgba(110, 120, 129, 0.5); }

.swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-styled:focus {
  outline: 0; }

.swal2-styled::-moz-focus-inner {
  border: 0; }

.swal2-footer {
  justify-content: center;
  margin: 1em 0 0;
  padding: 1em 1em 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em; }

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  grid-column: auto !important;
  height: .25em;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.swal2-timer-progress-bar {
  width: 100%;
  height: .25em;
  background: rgba(0, 0, 0, 0.2); }

.swal2-image {
  max-width: 100%;
  margin: 2em auto 1em; }

.swal2-close {
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: -1.2em;
  padding: 0;
  overflow: hidden;
  transition: color .1s,box-shadow .1s;
  border: none;
  border-radius: 5px;
  background: 0 0;
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end; }

.swal2-close:hover {
  transform: none;
  background: 0 0;
  color: #f27474; }

.swal2-close:focus {
  outline: 0;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-close::-moz-focus-inner {
  border: 0; }

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em .3em;
  padding: 0;
  overflow: auto;
  color: #545454;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word; }

.swal2-checkbox, .swal2-file, .swal2-input, .swal2-radio, .swal2-select, .swal2-textarea {
  margin: 1em 2em 0; }

.swal2-file, .swal2-input, .swal2-textarea {
  box-sizing: border-box;
  width: auto;
  transition: border-color .1s,box-shadow .1s;
  border: 1px solid #d9d9d9;
  border-radius: .1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px transparent;
  color: inherit;
  font-size: 1.125em; }

.swal2-file.swal2-inputerror, .swal2-input.swal2-inputerror, .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important; }

.swal2-file:focus, .swal2-input:focus, .swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-file::-moz-placeholder, .swal2-input::-moz-placeholder, .swal2-textarea::-moz-placeholder {
  color: #ccc; }

.swal2-file:-ms-input-placeholder, .swal2-input:-ms-input-placeholder, .swal2-textarea:-ms-input-placeholder {
  color: #ccc; }

.swal2-file::placeholder, .swal2-input::placeholder, .swal2-textarea::placeholder {
  color: #ccc; }

.swal2-range {
  margin: 1em 2em 0;
  background: #fff; }

.swal2-range input {
  width: 80%; }

.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center; }

.swal2-range input, .swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em; }

.swal2-input {
  height: 2.625em;
  padding: 0 .75em; }

.swal2-file {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  background: inherit;
  font-size: 1.125em; }

.swal2-textarea {
  height: 6.75em;
  padding: .75em; }

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em; }

.swal2-checkbox, .swal2-radio {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit; }

.swal2-checkbox label, .swal2-radio label {
  margin: 0 .6em;
  font-size: 1.125em; }

.swal2-checkbox input, .swal2-radio input {
  flex-shrink: 0;
  margin: 0 .4em; }

.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto 0; }

.swal2-validation-message {
  align-items: center;
  justify-content: center;
  margin: 1em 0 0;
  padding: .625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666;
  font-size: 1em;
  font-weight: 300; }

.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 .625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center; }

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto .6em;
  border: .25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em; }

.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474; }

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: .3125em;
  border-radius: .125em;
  background-color: #f27474; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg); }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-error.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  -webkit-animation: swal2-animate-error-x-mark .5s;
  animation: swal2-animate-error-x-mark .5s; }

.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86; }

.swal2-icon.swal2-warning.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-i-mark .5s;
  animation: swal2-animate-i-mark .5s; }

.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee; }

.swal2-icon.swal2-info.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-i-mark .8s;
  animation: swal2-animate-i-mark .8s; }

.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd; }

.swal2-icon.swal2-question.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-question-mark .8s;
  animation: swal2-animate-question-mark .8s; }

.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0; }

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -.25em;
  left: -.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%; }

.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: .8125em;
  width: 1.5625em;
  transform: rotate(45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-animate-success-line-tip .75s;
  animation: swal2-animate-success-line-tip .75s; }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-animate-success-line-long .75s;
  animation: swal2-animate-success-line-long .75s; }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
  animation: swal2-rotate-success-circular-line 4.25s ease-in; }

.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 1.25em auto;
  padding: 0;
  background: inherit;
  font-weight: 600; }

.swal2-progress-steps li {
  display: inline-block;
  position: relative; }

.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6; }

.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: .4em;
  margin: 0 -1px;
  background: #2778c4; }

[class^=swal2] {
  -webkit-tap-highlight-color: transparent; }

.swal2-show {
  -webkit-animation: swal2-show .3s;
  animation: swal2-show .3s; }

.swal2-hide {
  -webkit-animation: swal2-hide .15s forwards;
  animation: swal2-hide .15s forwards; }

.swal2-noanimation {
  transition: none; }

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0; }

.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto; }

@-webkit-keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0); } }

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0); } }

@-webkit-keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@-webkit-keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@-webkit-keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@-webkit-keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@-webkit-keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@-webkit-keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@-webkit-keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@-webkit-keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@-webkit-keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg); }
  100% {
    transform: rotateY(0); } }

@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg); }
  100% {
    transform: rotateY(0); } }

@-webkit-keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0; }
  25% {
    transform: rotateZ(-25deg);
    opacity: .4; }
  50% {
    transform: rotateZ(15deg);
    opacity: .8; }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0; }
  25% {
    transform: rotateZ(-25deg);
    opacity: .4; }
  50% {
    transform: rotateZ(15deg);
    opacity: .8; }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden; }

body.swal2-height-auto {
  height: auto !important; }

body.swal2-no-backdrop .swal2-container {
  background-color: transparent !important;
  pointer-events: none; }

body.swal2-no-backdrop .swal2-container .swal2-popup {
  pointer-events: all; }

body.swal2-no-backdrop .swal2-container .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }

@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important; }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden=true] {
    display: none; }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important; } }

body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: 360px;
  max-width: 100%;
  background-color: transparent;
  pointer-events: none; }

body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%); }

body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto; }

body.swal2-toast-shown .swal2-container.swal2-top-left, body.swal2-toast-shown .swal2-container.swal2-top-start {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0; }

body.swal2-toast-shown .swal2-container.swal2-center-left, body.swal2-toast-shown .swal2-container.swal2-center-start {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%); }

body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%); }

body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%); }

body.swal2-toast-shown .swal2-container.swal2-bottom-left, body.swal2-toast-shown .swal2-container.swal2-bottom-start {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0; }

body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }

body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-BlackItalic.eot");
  src: local("Source Sans Pro Black Italic"), local("SourceSansPro-BlackItalic"), url("../fonts/SourceSansPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-BlackItalic.woff") format("woff"), url("../fonts/SourceSansPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-BoldItalic.eot");
  src: local("Source Sans Pro Bold Italic"), local("SourceSansPro-BoldItalic"), url("../fonts/SourceSansPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-BoldItalic.woff") format("woff"), url("../fonts/SourceSansPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-Regular.eot");
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url("../fonts/SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-Regular.woff") format("woff"), url("../fonts/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-SemiBoldItalic.eot");
  src: local("Source Sans Pro SemiBold Italic"), local("SourceSansPro-SemiBoldItalic"), url("../fonts/SourceSansPro-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-SemiBoldItalic.woff") format("woff"), url("../fonts/SourceSansPro-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-LightItalic.eot");
  src: local("Source Sans Pro Light Italic"), local("SourceSansPro-LightItalic"), url("../fonts/SourceSansPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-LightItalic.woff") format("woff"), url("../fonts/SourceSansPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-Italic.eot");
  src: local("Source Sans Pro Italic"), local("SourceSansPro-Italic"), url("../fonts/SourceSansPro-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-Italic.woff") format("woff"), url("../fonts/SourceSansPro-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-ExtraLightItalic.eot");
  src: local("Source Sans Pro ExtraLight Italic"), local("SourceSansPro-ExtraLightItalic"), url("../fonts/SourceSansPro-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-ExtraLightItalic.woff") format("woff"), url("../fonts/SourceSansPro-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-ExtraLight.eot");
  src: local("Source Sans Pro ExtraLight"), local("SourceSansPro-ExtraLight"), url("../fonts/SourceSansPro-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-ExtraLight.woff") format("woff"), url("../fonts/SourceSansPro-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-Light.eot");
  src: local("Source Sans Pro Light"), local("SourceSansPro-Light"), url("../fonts/SourceSansPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-Light.woff") format("woff"), url("../fonts/SourceSansPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-Black.eot");
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"), url("../fonts/SourceSansPro-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-Black.woff") format("woff"), url("../fonts/SourceSansPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-Bold.eot");
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("../fonts/SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-Bold.woff") format("woff"), url("../fonts/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/SourceSansPro-SemiBold.eot");
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"), url("../fonts/SourceSansPro-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSansPro-SemiBold.woff") format("woff"), url("../fonts/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

body,
.adaptiv-fix {
  min-height: 100vh;
  margin: 0;
  color: #121212;
  font-family: 'Source Sans Pro' !important;
  display: flex;
  flex-direction: column;
  position: relative; }
  body.opened,
  .adaptiv-fix.opened {
    overflow: hidden; }

main {
  position: relative;
  flex: 1 0 auto; }

p, ol, ul, h1, h2, h3, button, form {
  margin: 0;
  padding: 0; }

em {
  font-style: normal;
  color: #1AC5F4; }

button, input, textarea {
  font-family: inherit; }
  button:focus, input:focus, textarea:focus {
    outline: none; }

input {
  min-width: 0;
  font-size: inherit;
  appearance: none; }

ul, li {
  list-style-type: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield; }

a {
  text-decoration: none;
  transition: color 0.3s; }

svg {
  vertical-align: middle;
  transition: fill 0.3s; }

picture {
  display: block; }

img {
  display: block; }

source {
  display: none; }

*, ::before, ::after {
  box-sizing: border-box; }

:focus {
  outline: none; }

.container {
  max-width: calc(1755px + 30px);
  margin: 0 auto;
  padding: 0 15px; }

.loading,
.disabled {
  opacity: .4;
  pointer-events: none;
  user-select: none; }

.show-after-load {
  opacity: 0 !important;
  visibility: hidden !important; }

#pageContainer {
  max-width: 1455px;
  margin: 0 auto;
  padding: 0 0 100px; }
  @media (max-width: 1199px) {
    #pageContainer {
      padding: 0 0 50px; } }

.js-openall .hide {
  display: none; }

.btn {
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center; }
  .btn--red {
    background-color: #FF0033;
    border-radius: 8px;
    box-shadow: 0px 12px 30px rgba(255, 0, 51, 0.4);
    transition-property: box-shadow, background-color;
    transition-duration: 0.3s; }
    .btn--red span {
      font-weight: 700;
      font-size: 21px;
      line-height: 26px;
      color: #FFFFFF; }
    @media (max-width: 1199px) {
      .btn--red span {
        font-size: 16px;
        line-height: 24px; } }
    .btn--red:not(.success):hover {
      background-color: #D2002A; }
    .btn--red.success {
      background-color: #2BAD16;
      box-shadow: 0px 12px 30px rgba(43, 173, 22, 0.4); }
  .btn--openall {
    position: relative;
    margin: 15px 0 0;
    font-size: 21px;
    line-height: 26px;
    color: #012472;
    transition: color 0.3s; }
    @media (max-width: 1199px) {
      .btn--openall {
        font-size: 15px;
        line-height: 18px; } }
    .btn--openall::after {
      content: '';
      display: block;
      position: relative;
      border-top: 1px solid #012472;
      border-right: 1px solid #012472;
      transform: rotate(135deg);
      width: 10px;
      height: 10px;
      margin: -3px 0px 0px 14px;
      transition: 0.3s; }
      @media (max-width: 1199px) {
        .btn--openall::after {
          width: 8px;
          height: 8px;
          margin: -4px 0px 0px 14px; } }
    .btn--openall:hover {
      color: #1AC5F4; }
      .btn--openall:hover::after {
        border-color: #1AC5F4; }
    .btn--openall.opened::after {
      transform: rotate(315deg);
      margin: 8px 0 0 14px; }
  .btn--act {
    padding: 10px; }
    .btn--act svg {
      width: 24px;
      height: 24px; }
    .btn--act[data-state="added"] svg {
      fill: #FF0033; }
  .btn--border {
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    color: #012472;
    border: 1px solid #012472;
    border-radius: 8px;
    transition-property: border-color, color;
    transition-duration: 0.3s; }
    @media (max-width: 1199px) {
      .btn--border {
        font-size: 16px;
        line-height: 24px; } }
    .btn--border:hover {
      border-color: #1AC5F4;
      color: #1AC5F4; }
  .btn--filled {
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    color: #FFFFFF;
    background: #012472;
    border-radius: 8px;
    box-shadow: 0px 12px 30px rgba(1, 36, 114, 0.37);
    transition: background-color 0.3s; }
    @media (max-width: 1199px) {
      .btn--filled {
        font-size: 16px;
        line-height: 24px; } }
    .btn--filled:hover {
      background-color: #00184D; }
  .btn--dotted {
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    color: #9A9BA8;
    transition: color 0.3s; }
    .btn--dotted span {
      display: block;
      border-bottom: 1px dashed #9A9BA8;
      transition: border-color 0.3s; }
    .btn--dotted:hover {
      color: #1AC5F4; }
      .btn--dotted:hover span {
        border-color: #1AC5F4; }
  .btn--back {
    width: fit-content;
    align-items: center; }
    .btn--back:hover svg {
      fill: #1AC5F4; }
    .btn--back:hover span {
      color: #1AC5F4; }
    .btn--back svg {
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
      fill: #121212;
      margin-right: 10px;
      transition: fill 0.3s; }
    .btn--back span {
      font-size: 21px;
      line-height: 26px;
      color: #121212;
      transition: color 0.3s; }
  .btn--swal {
    height: 45px;
    padding: 0px 20px;
    margin: 0 10px; }
    @media (max-width: 1199px) {
      .btn--swal {
        padding: 0 10px;
        margin: 0 5px; } }
    @media (max-width: 575px) {
      .btn--swal {
        padding: 0;
        margin: 0; } }
  .btn--s-login {
    padding: 16px 45px; }

.icon {
  display: inline-block;
  transition: opacity 0.3s; }
  .icon:hover {
    opacity: 0.8; }

.breadcrumbs {
  padding: 25px 0 50px; }
  @media (max-width: 1199px) {
    .breadcrumbs {
      padding: 15px 0 20px; } }
  .breadcrumbs-item {
    font-size: 18px;
    line-height: 24px;
    color: #121212; }
    @media (max-width: 1199px) {
      .breadcrumbs-item {
        font-size: 15px;
        line-height: 18px; } }
    .breadcrumbs-item--link {
      transition: color 0.3s; }
      .breadcrumbs-item--link:hover {
        color: #1AC5F4; }
    .breadcrumbs-item--sep {
      color: #9A9BA8;
      padding: 0 10px; }
      @media (max-width: 1199px) {
        .breadcrumbs-item--sep {
          padding: 0 3px; } }
    .breadcrumbs-item:last-child {
      color: #9A9BA8; }
  .breadcrumbs-signup-alert {
    padding: 0 0 25px; }

.page-title {
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  color: #121212; }
  .page-title--solo {
    margin: 0 0 35px; }
    @media (max-width: 1199px) {
      .page-title--solo {
        margin: 0 0 25px; } }
  @media (max-width: 1199px) {
    .page-title {
      font-size: 24px;
      line-height: 28px; } }

.help-text {
  cursor: pointer;
  position: relative; }
  .help-text__icon svg {
    width: 24px;
    height: 24px;
    fill: #1AC5F4; }
  .help-text__alert {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    font-size: 18px;
    line-height: 22px;
    color: #121212;
    background: #FFFFFF;
    box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px 30px;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    z-index: 9; }
    @media (max-width: 1199px) {
      .help-text__alert {
        left: unset;
        right: 0;
        font-size: 12px;
        line-height: 18px;
        max-width: 270px;
        padding: 5px 12px; } }
  .help-text:hover .help-text__alert {
    opacity: 1;
    visibility: visible; }

.theme-notify {
  background-color: #1AC5F4;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  padding: 24px 40px; }
  @media (max-width: 1199px) {
    .theme-notify {
      padding: 15px; } }
  .theme-notify__text {
    position: relative;
    z-index: 1;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF; }
    @media (max-width: 1199px) {
      .theme-notify__text {
        font-size: 15px;
        line-height: 18px; } }
    .theme-notify__text a {
      color: #fff;
      text-decoration: underline; }
      .theme-notify__text a:hover {
        text-decoration: none; }
  .theme-notify__decor {
    position: absolute;
    background-color: #012472;
    border-radius: 50%; }
    .theme-notify__decor--1 {
      width: 200px;
      height: 200px;
      left: -50px;
      top: -100px; }
    .theme-notify__decor--2 {
      width: 35px;
      height: 35px;
      left: 180px;
      top: -20px; }

.theme-tabs__head {
  display: flex;
  flex-direction: row;
  align-items: center; }

.theme-tabs__name {
  cursor: pointer;
  padding: 15px 26px;
  background-color: #FFFFFF;
  box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid transparent;
  font-size: 21px;
  line-height: 26px;
  color: #121212;
  transition: 0.3s; }
  @media (max-width: 1199px) {
    .theme-tabs__name {
      font-size: 15px;
      line-height: 18px;
      padding: 8px 10px; } }
  .theme-tabs__name:not(:last-child) {
    margin-right: 14px; }
    @media (max-width: 1199px) {
      .theme-tabs__name:not(:last-child) {
        margin-right: 10px; } }
  .theme-tabs__name:not(.active):hover {
    color: #1AC5F4; }
  .theme-tabs__name.active {
    cursor: default;
    border-color: #C7C8CF;
    box-shadow: none; }

.theme-tabs__body {
  padding: 25px 0 0; }
  @media (max-width: 1199px) {
    .theme-tabs__body {
      padding: 15px 0 0; } }

.theme-tabs__container {
  display: none; }
  .theme-tabs__container.active {
    display: block; }

.theme-sort {
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media (max-width: 1199px) {
    .theme-sort {
      align-items: flex-start;
      flex-direction: column; } }
  .theme-sort__name {
    font-size: 18px;
    line-height: 24px;
    color: #121212; }
    @media (max-width: 1199px) {
      .theme-sort__name {
        font-size: 15px;
        line-height: 18px; } }
  .theme-sort__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px; }
    @media (max-width: 1199px) {
      .theme-sort__items {
        padding: 5px 0 0; } }
    .theme-sort__items .radio-sort:not(:last-child) {
      margin-right: 10px; }
      @media (max-width: 1199px) {
        .theme-sort__items .radio-sort:not(:last-child) {
          margin-right: 5px; } }

.radio-sort {
  display: block; }
  .radio-sort__field {
    display: none; }
    .radio-sort__field:checked + .radio-sort__name {
      border-color: #121212;
      cursor: default; }
    .radio-sort__field:not(:checked) + .radio-sort__name:hover {
      color: #1AC5F4; }
      .radio-sort__field:not(:checked) + .radio-sort__name:hover::after {
        border-color: #1AC5F4; }
  .radio-sort__name {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    color: #121212;
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid #C7C8CF;
    border-radius: 18px;
    padding: 5px 25px;
    transition: color 0.3s;
    user-select: none; }
    @media (max-width: 1199px) {
      .radio-sort__name {
        font-size: 12px;
        line-height: 16px;
        padding: 4px 8px; } }
    .radio-sort__name::after {
      content: '';
      display: block;
      position: relative;
      width: 10px;
      height: 10px;
      border-top: 1px solid #121212;
      border-right: 1px solid #121212;
      transform: rotate(135deg);
      margin-left: 10px;
      top: -2px;
      transition-property: border-color, transform, top;
      transition-duration: 0.3s; }
    .radio-sort__name--reverse::after {
      transform: rotate(315deg);
      top: 3px; }

.theme-color-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px; }
  @media (max-width: 1199px) {
    .theme-color-label {
      font-size: 12px;
      line-height: 16px; } }
  .theme-color-label--red {
    color: #FF0033; }
  .theme-color-label--yellow {
    color: #D19B11; }
  .theme-color-label--green {
    color: #2BAD16; }

.theme-counter {
  width: 145px;
  height: 45px;
  background: #FFFFFF;
  border: 1px solid #C7C8CF;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 42px 1fr 42px; }
  @media (max-width: 1199px) {
    .theme-counter {
      height: 35px;
      width: 110px;
      grid-template-columns: 30px 1fr 30px; } }
  .theme-counter__button {
    user-select: none; }
    .theme-counter__button svg {
      width: 14px;
      height: 14px;
      fill: #121212;
      transition: fill 0.3s; }
      @media (max-width: 1199px) {
        .theme-counter__button svg {
          width: 8px;
          height: 8px; } }
    .theme-counter__button:hover svg {
      fill: #1AC5F4; }
  .theme-counter__field {
    border: none;
    border-left: 1px solid #C7C8CF;
    border-right: 1px solid #C7C8CF;
    text-align: center;
    font-size: 21px;
    line-height: 26px; }
    @media (max-width: 1199px) {
      .theme-counter__field {
        font-size: 16px;
        line-height: 18px; } }

.theme-fileblock {
  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center; }
  @media (max-width: 1199px) {
    .theme-fileblock {
      grid-template-columns: 30px 1fr; } }
  .theme-fileblock__icon svg {
    width: 60px;
    height: 60px;
    fill: #012472; }
    @media (max-width: 1199px) {
      .theme-fileblock__icon svg {
        width: 30px;
        height: 30px; } }
  .theme-fileblock__name {
    margin-left: 5px; }
    .theme-fileblock__name p {
      font-size: 21px;
      line-height: 26px;
      color: #121212;
      margin: 0 0 5px; }
      @media (max-width: 1199px) {
        .theme-fileblock__name p {
          font-size: 15px;
          line-height: 18px;
          margin: 0; } }
    .theme-fileblock__name span {
      font-size: 18px;
      line-height: 22px;
      color: #626262; }
      @media (max-width: 1199px) {
        .theme-fileblock__name span {
          font-size: 12px;
          line-height: 14px; } }

.swiper {
  transition: opacity 0.3s;
  opacity: 1; }
  .swiper-init {
    opacity: 0; }
  .swiper-pagination {
    position: relative;
    z-index: 1; }
    .swiper-pagination-bullet {
      opacity: 1;
      width: 12px;
      height: 12px;
      border: 1px solid #012472;
      background-color: transparent;
      transition: background-color 0.3s;
      margin: 0 9px; }
      .swiper-pagination-bullet-active {
        background-color: #012472; }
      @media (max-width: 991px) {
        .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          margin: 0 4px; } }
  .swiper-navigation {
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 40px;
    border: 1px solid #C7C8CF;
    border-radius: 8px;
    transition: border-color 0.3s; }
    .swiper-navigation::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border-top: 1px solid #121212;
      border-right: 1px solid #121212;
      transform: rotate(225deg);
      left: 0;
      right: -4px;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: border-color 0.3s; }
    .swiper-navigation:hover {
      border-color: #012472; }
      .swiper-navigation:hover::before {
        border-color: #012472; }
    .swiper-navigation__next {
      transform: rotate(180deg);
      margin-left: 30px; }
    .swiper-navigation__prev {
      margin-right: 30px; }

.swiper-button-disabled {
  opacity: .6;
  pointer-events: none; }

.no-photo {
  background-image: url("../images/placeholder.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.simple-counter {
  cursor: pointer;
  user-select: none;
  display: grid;
  grid-template-columns: 28px 1fr 28px;
  background: #FFFFFF;
  align-items: center; }
  @media (max-width: 991px) {
    .simple-counter {
      grid-template-columns: 18px 1fr 18px; } }
  .simple-counter__minus svg, .simple-counter__plus svg {
    width: 28px;
    height: 28px;
    fill: #121212;
    transition: fill 0.3s; }
    @media (max-width: 991px) {
      .simple-counter__minus svg, .simple-counter__plus svg {
        width: 18px !important;
        height: 18px !important; } }
  .simple-counter__minus:hover svg, .simple-counter__plus:hover svg {
    fill: #1AC5F4; }
  .simple-counter__field {
    border: none;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #121212; }
    @media (max-width: 991px) {
      .simple-counter__field {
        font-size: 22px !important;
        line-height: 26px !important; } }

@media (max-width: 1199px) {
  .swal2-title {
    padding: 10px 20px 0;
    font-size: 22px; } }

@media (max-width: 1199px) {
  .swal2-html-container {
    font-size: 16px; } }

@media (max-width: 575px) {
  .swal2-actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    margin: 0;
    padding: 10px 15px 0; }
    .swal2-actions .btn--swal {
      width: 100%;
      margin: 0; } }

@media (max-width: 1199px) {
  .swal2-icon {
    width: 50px;
    height: 50px; }
    .swal2-icon .swal2-icon-content {
      font-size: 30px; } }

.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
  border-color: #012472 !important; }

.folowing-button {
  position: fixed;
  left: calc(100% - 142px);
  top: 48%;
  background-color: #FF0033;
  border-radius: 7.5px;
  padding: 8px 44px 10px;
  transform: rotate(-90deg);
  cursor: pointer;
  z-index: 2;
  transition: background-color .3s ease; }
  @media (max-width: 991px) {
    .folowing-button {
      top: 92%;
      transform: none;
      left: 73%;
      z-index: 5; } }
  @media (max-width: 575px) {
    .folowing-button {
      display: flex;
      justify-content: center;
      width: 96%;
      top: 93%;
      left: 2%; } }
  .folowing-button:hover {
    background-color: #d2002a; }
  .folowing-button span {
    font-size: 21px;
    font-weight: 700;
    line-height: 22px;
    color: #fff; }

.theme-form {
  position: relative; }
  .theme-form.load > :not(.theme-loader) {
    opacity: 0.3; }

.theme-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 5; }
  .theme-loader img {
    width: 30%;
    height: 50%; }

textarea {
  padding: 15px 24px !important;
  font-size: 22px;
  line-height: 26px;
  color: #121212; }

.theme-input {
  display: block;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  padding: 25px 24px 7px;
  width: 100%;
  transition-property: border-color;
  transition-duration: 0.3s;
  cursor: text; }
  @media (max-width: 1199px) {
    .theme-input {
      padding: 18px 18px 6px; } }
  .theme-input--border {
    border: 1px solid #C7C8CF; }
  .theme-input--disabled {
    opacity: 0.8;
    pointer-events: none;
    user-select: none; }
  .theme-input--solo {
    grid-column: 1/-1; }
  .theme-input:not(:last-child) {
    margin: 0 0 10px; }
  .theme-input.error {
    border-color: #8300EA; }
    .theme-input.error::after {
      content: '!';
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #8300EA;
      color: #8300EA;
      border-radius: 50%;
      right: 20px;
      font-size: 14px;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media (max-width: 1199px) {
        .theme-input.error::after {
          font-size: 12px;
          right: 15px;
          width: 15px;
          height: 15px; } }
    .theme-input.error .theme-input__placeholder {
      color: #8300EA; }
  .theme-input__field {
    border: none;
    font-size: 22px;
    line-height: 26px;
    color: #121212;
    width: 100%;
    background-color: transparent;
    position: relative;
    z-index: 1; }
    @media (max-width: 1199px) {
      .theme-input__field {
        font-size: 15px;
        line-height: 18px; } }
    .theme-input__field::placeholder {
      opacity: 0; }
    .theme-input__field:disabled {
      opacity: .6; }
    .theme-input__field:focus + span,
    .theme-input__field:not(:placeholder-shown) + span {
      top: 10px;
      font-size: 12px;
      line-height: 16px; }
      @media (max-width: 1199px) {
        .theme-input__field:focus + span,
        .theme-input__field:not(:placeholder-shown) + span {
          top: 5px; } }
  .theme-input__placeholder {
    position: absolute;
    display: block;
    font-size: 21px;
    line-height: 26px;
    color: #9A9BA8;
    left: 24px;
    top: calc(50% - 12px);
    transition-property: font-size, top, color;
    transition-duration: 0.3s; }
    @media (max-width: 1199px) {
      .theme-input__placeholder {
        font-size: 15px;
        line-height: 18px;
        left: 18px;
        top: calc(50% - 10px); } }
  .theme-input__password-toggle {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 15px;
    top: calc(50% - 12px);
    fill: #999999;
    cursor: pointer;
    transition: fill 0.3s;
    z-index: 3; }
    .theme-input__password-toggle.active {
      fill: #1AC5F4; }

.theme-textarea__field {
  resize: none;
  height: 150px; }

.theme-textarea__placeholder {
  top: 15px; }

.theme-toggle {
  user-select: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: 65px 1fr;
  align-items: center; }
  .theme-toggle:not(:last-child) {
    margin: 0 0 10px; }
  .theme-toggle:hover .theme-toggle__name {
    color: #1AC5F4; }
  .theme-toggle__input {
    display: none; }
    .theme-toggle__input:checked + div {
      background-color: #012472;
      border-color: #012472; }
      .theme-toggle__input:checked + div::before {
        right: 3px;
        border-color: #012472; }
  .theme-toggle__fake {
    position: relative;
    width: 50px;
    height: 28px;
    background-color: #E3E3E3;
    border: 1px solid #D0D0D0;
    transition: 0.3s; }
    .theme-toggle__fake::before {
      content: '';
      display: block;
      position: absolute;
      width: 50%;
      height: calc(100% - 4px);
      background-color: #FFFFFF;
      border: 1px solid #D0D0D0;
      top: 2px;
      right: calc(100% - 50% - 3px);
      transition: 0.3s; }
  .theme-toggle__name {
    font-size: 22px;
    line-height: 28px;
    color: #121212;
    transition: color 0.3s; }
    @media (max-width: 991px) {
      .theme-toggle__name {
        font-size: 14px;
        line-height: 18px; } }

.choice-block {
  cursor: pointer;
  user-select: none; }
  .choice-block__field {
    display: none; }
    .choice-block__field:checked + .choice-block__front {
      border-color: #D0D0D0;
      background-color: transparent; }
      .choice-block__field:checked + .choice-block__front .choice-block__fake {
        background-color: #282828;
        border-color: #282828; }
        .choice-block__field:checked + .choice-block__front .choice-block__fake svg {
          fill: #FFFFFF; }
  .choice-block__front {
    background-color: #F8F8F8;
    border: 1px solid transparent;
    padding: 20px 18px;
    transition: 0.3s;
    width: 100%;
    height: 100%; }
  .choice-block__fake {
    width: 22px;
    height: 22px;
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s; }
    .choice-block__fake svg {
      width: 12px;
      height: 9px;
      fill: transparent;
      transition: fill 0.3s; }
  .choice-block__title {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    margin: 12px 0 0; }
  .choice-block__desc {
    font-size: 14px;
    line-height: 18px;
    color: #7D7D7D;
    margin: 8px 0 0; }
  .choice-block__link {
    padding: 10px 0 0;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline; }
    .choice-block__link:hover {
      color: #1AC5F4; }

.theme-checkbox {
  cursor: pointer;
  display: grid;
  grid-template-columns: calc(28px + 15px) 1fr;
  align-items: center;
  user-select: none; }
  @media (max-width: 1199px) {
    .theme-checkbox {
      grid-template-columns: calc(15px + 10px) 1fr; } }
  .theme-checkbox__field {
    display: none; }
    .theme-checkbox__field:checked + div {
      background-color: #1AC5F4;
      border-color: #1AC5F4; }
  .theme-checkbox__fake {
    position: relative;
    width: 28px;
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 2px;
    transition: 0.3s; }
    @media (max-width: 1199px) {
      .theme-checkbox__fake {
        width: 15px;
        height: 15px; } }
    .theme-checkbox__fake::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("../images/check.png");
      background-size: 24px 24px;
      background-repeat: no-repeat;
      background-position: center; }
      @media (max-width: 1199px) {
        .theme-checkbox__fake::before {
          background-size: 12px 12px; } }
  .theme-checkbox__name {
    font-size: 21px;
    line-height: 26px;
    color: #121212;
    position: relative;
    top: 2px; }
    @media (max-width: 1199px) {
      .theme-checkbox__name {
        font-size: 15px;
        line-height: 18px; } }

.g-recaptcha {
  overflow: hidden; }

.theme-policy {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  margin: 8px 0 0; }
  .theme-policy a {
    color: #FFFFFF;
    text-decoration: underline; }
    .theme-policy a:hover {
      text-decoration: none; }
  .theme-policy--gray {
    color: #9A9BA8; }
    .theme-policy--gray a {
      color: #121212; }
  .theme-policy--center {
    text-align: center; }
  @media (max-width: 1199px) {
    .theme-policy {
      font-size: 14px;
      line-height: 18px; } }

.noUi-horizontal {
  height: 4px !important; }

.noUi-target {
  background: #f0f0f2 !important;
  border: none !important;
  box-shadow: none !important;
  margin: 0 10px; }

.noUi-connect {
  background-color: #1AC5F4 !important; }

.noUi-horizontal .noUi-handle {
  cursor: pointer;
  width: 24px !important;
  height: 24px !important;
  background-color: #FFFFFF;
  border: 1px solid #1AC5F4;
  border-radius: 50%;
  box-shadow: none;
  right: -10px !important;
  top: -10px !important; }
  @media (max-width: 1199px) {
    .noUi-horizontal .noUi-handle {
      top: -7px !important;
      width: 18px !important;
      height: 18px !important; } }
  .noUi-horizontal .noUi-handle:after, .noUi-horizontal .noUi-handle:before {
    display: none; }

.noUi-touch-area {
  display: none; }

.radio-block {
  cursor: pointer; }
  .radio-block:not(:last-child) {
    margin-right: 10px; }
  .radio-block__field {
    display: none; }
    .radio-block__field:checked + span {
      border-color: #1AC5F4; }
  .radio-block__name {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 3px;
    padding: 10px 20px;
    transition: border-color 0.3s; }
    .radio-block__name:hover {
      border-color: #1AC5F4; }

.theme-select {
  position: relative; }
  .theme-select__list {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100% + 15px);
    left: -1px;
    min-width: calc(100% + 2px);
    border: 1px solid #c9ced8;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    box-shadow: 4px 6px 8px 0px rgba(35, 60, 80, 0.2);
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    transition: 0.3s;
    z-index: 3; }
    .theme-select__list.opened {
      visibility: visible;
      opacity: 1;
      top: calc(100% + 5px); }
    .theme-select__list label {
      cursor: pointer;
      padding: 3px 10px;
      transition: background-color 0.3s; }
      .theme-select__list label:hover {
        background-color: #eaeaea; }
      .theme-select__list label input {
        display: none; }
      .theme-select__list label span {
        font-size: 16px;
        line-height: 24px;
        color: #282828;
        white-space: nowrap; }

.radio-image {
  display: block;
  cursor: pointer; }
  .radio-image:not(:last-child) {
    margin-right: 16px; }
    @media (max-width: 991px) {
      .radio-image:not(:last-child) {
        margin-right: 8px; } }
  .radio-image__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .radio-image__field {
    display: none; }
    .radio-image__field:checked + div {
      outline-color: #1AC5F4; }
  .radio-image__image {
    width: 85px;
    height: 60px;
    outline: 3px solid;
    outline-color: transparent;
    outline-offset: -3px;
    transition: outline-color 0.3s;
    border: 1px solid #DADADA; }
    @media (max-width: 991px) {
      .radio-image__image {
        width: 65px;
        height: 45px; } }
    .radio-image__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.file-upload {
  position: relative;
  overflow: hidden;
  display: block;
  cursor: pointer;
  background-color: #FFFFFF;
  box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px; }
  .file-upload.dragenter .file-upload__dragzone {
    opacity: 1;
    z-index: 11; }
  .file-upload.dragenter .file-upload__wrap {
    pointer-events: none; }
  .file-upload.dragenter .file-upload__wrap, .file-upload:hover .file-upload__wrap {
    border-color: #1AC5F4; }
  .file-upload.dragenter .file-upload__title-icon svg, .file-upload:hover .file-upload__title-icon svg {
    fill: #1AC5F4; }
  .file-upload.dragenter .file-upload__title-text, .file-upload:hover .file-upload__title-text {
    color: #1AC5F4; }
  @media (max-width: 1199px) {
    .file-upload {
      padding: 6px; } }
  .file-upload:not(:last-child) {
    margin: 0 0 10px; }
  .file-upload.active .file-upload__active {
    display: block; }
  .file-upload__wrap {
    border: 1px dashed #9A9BA8;
    border-radius: 8px;
    padding: 16px 20px 20px;
    transition: border-color 0.3s;
    position: relative;
    overflow: hidden; }
    @media (max-width: 1199px) {
      .file-upload__wrap {
        padding: 10px 20px; } }
  .file-upload__title {
    display: grid;
    grid-template-columns: 55px 1fr;
    align-items: center; }
    @media (max-width: 1199px) {
      .file-upload__title {
        grid-template-columns: 35px 1fr; } }
    .file-upload__title-icon svg {
      width: 35px;
      height: 35px;
      fill: #121212;
      transition: fill 0.3s; }
      @media (max-width: 1199px) {
        .file-upload__title-icon svg {
          width: 22px;
          height: 22px; } }
    .file-upload__title-text {
      font-size: 21px;
      line-height: 26px;
      color: #121212;
      transition: color 0.3s; }
      @media (max-width: 1199px) {
        .file-upload__title-text {
          font-size: 14px;
          line-height: 18px; } }
  .file-upload__info {
    margin: 15px 0 0;
    font-size: 18px;
    line-height: 22px;
    color: #9A9BA8; }
    @media (max-width: 1199px) {
      .file-upload__info {
        font-size: 12px;
        line-height: 16px;
        margin: 8px 0 0; } }
  .file-upload__active {
    display: none;
    padding: 20px 0 0; }
  .file-upload__filename {
    font-size: 18px;
    line-height: 22px;
    color: #121212; }
    @media (max-width: 1199px) {
      .file-upload__filename {
        font-size: 14px;
        line-height: 18px; } }
  .file-upload__filedelete {
    color: #f03;
    font-size: 18px;
    line-height: 22px;
    border-bottom: 1px dashed #f03;
    margin: 4px 0 0; }
    @media (max-width: 1199px) {
      .file-upload__filedelete {
        font-size: 14px;
        line-height: 18px; } }
  .file-upload__dragzone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    pointer-events: none; }
    .file-upload__dragzone > div {
      max-width: 225px;
      pointer-events: none; }
    .file-upload__dragzone .file-upload__title-icon svg {
      fill: #1AC5F4; }
    .file-upload__dragzone .file-upload__title-text {
      color: #1AC5F4; }
  .file-upload input {
    display: none; }

.theme-submit {
  height: 60px;
  width: 100%; }
  @media (max-width: 1199px) {
    .theme-submit {
      height: 45px; } }

.theme-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .theme-success svg {
    width: 36px;
    height: 28px;
    fill: #14A356;
    margin: 0 0 25px; }
  .theme-success span {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #333333; }
    @media (max-width: 991px) {
      .theme-success span {
        font-size: 24px;
        line-height: 28px; } }

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow-y: scroll; }

.modal__container {
  background-color: #fff;
  padding: 60px 90px;
  max-height: 95vh;
  overflow: auto;
  max-width: 820px; }
  @media (max-width: 1199px) {
    .modal__container {
      max-width: 576px;
      padding: 25px; } }

.modal__content-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  margin-bottom: 15px; }
  @media (max-width: 767px) {
    .modal__content-form-row {
      grid-template-columns: 1fr;
      grid-row-gap: 15px; } }
  .modal__content-form-row .theme-input {
    margin: 0; }

.modal__content .order-form {
  padding: 0;
  border: none; }

.modal__title {
  font-weight: 700;
  font-size: 48px;
  line-height: 54px;
  color: #121212;
  margin: 0 0 25px; }
  .modal__title--center {
    text-align: center; }
  @media (max-width: 1199px) {
    .modal__title {
      font-size: 28px;
      line-height: 34px; } }

.modal__subtitle {
  font-weight: normal;
  display: block;
  font-size: 22px;
  line-height: 26px;
  color: #121212;
  margin: 15px 0 0; }
  @media (max-width: 1199px) {
    .modal__subtitle {
      font-size: 15px;
      line-height: 18px; }
      .modal__subtitle br {
        display: none; } }

.modal__close {
  position: absolute;
  cursor: pointer;
  border: none;
  font-size: 38px;
  line-height: 38px;
  background-color: transparent;
  top: 0;
  right: 0;
  padding: 15px 30px;
  color: #121212;
  transition: color 0.3s; }
  @media (max-width: 991px) {
    .modal__close {
      font-size: 30px;
      line-height: 30px;
      padding: 8px 14px; } }
  .modal__close:hover {
    color: #1AC5F4; }

@keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mmslideIn {
  from { }
  to { } }

@keyframes mmslideOut {
  from { }
  to { } }

.micromodal-slide {
  display: none; }

.micromodal-slide.is-open {
  display: block; }

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform; }

#coockie .modal__container {
  overflow: visible;
  max-width: 1200px;
  height: 240px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 9999;
  padding: 50px 100px; }
  @media (max-width: 900px) {
    #coockie .modal__container {
      height: 200px;
      padding: 30px 50px; } }
  @media (max-width: 600px) {
    #coockie .modal__container {
      height: 210px;
      padding: 30px 35px; } }
  @media (max-width: 360px) {
    #coockie .modal__container {
      height: 210px;
      padding: 30px 20px; } }
  #coockie .modal__container .modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; }
    #coockie .modal__container .modal__content .btn {
      color: white;
      font-size: 20px;
      font-weight: bold; }
      @media (max-width: 900px) {
        #coockie .modal__container .modal__content .btn {
          font-size: 18px; } }
      @media (max-width: 600px) {
        #coockie .modal__container .modal__content .btn {
          font-size: 14px; } }
  #coockie .modal__container .modal__close {
    color: orange; }
    #coockie .modal__container .modal__close:hover {
      color: #1AC5F4; }
  #coockie .modal__container .modal__content p {
    font-size: 24px;
    font-weight: 700;
    text-align: center; }
    @media (max-width: 1060px) {
      #coockie .modal__container .modal__content p {
        font-size: 22px; } }
    @media (max-width: 900px) {
      #coockie .modal__container .modal__content p {
        font-size: 18px; } }
    @media (max-width: 600px) {
      #coockie .modal__container .modal__content p {
        font-size: 14px; } }

.catalog-card {
  display: grid;
  grid-template-columns: 110px 1fr; }
  @media (max-width: 1199px) {
    .catalog-card {
      grid-template-columns: 80px 1fr; } }
  .catalog-card--no-photo {
    display: block; }
    .catalog-card--no-photo .catalog-card__main a {
      font-size: 21px;
      line-height: 26px; }
    .catalog-card--no-photo .catalog-card__sub a {
      font-size: 18px;
      line-height: 22px; }
  .catalog-card__image {
    width: 90px;
    height: 90px; }
    .catalog-card__image img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    @media (max-width: 1199px) {
      .catalog-card__image {
        width: 60px;
        height: 60px; } }
  .catalog-card__main:not(:last-child) {
    margin: 0 0 15px; }
    @media (max-width: 1199px) {
      .catalog-card__main:not(:last-child) {
        margin: 0 0 10px; } }
  .catalog-card__main a {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #121212; }
    @media (max-width: 1199px) {
      .catalog-card__main a {
        font-size: 18px;
        line-height: 22px; } }
    .catalog-card__main a:hover {
      color: #1AC5F4; }
  .catalog-card__sub a {
    display: block;
    font-size: 21px;
    line-height: 26px;
    color: #121212; }
    @media (max-width: 1199px) {
      .catalog-card__sub a {
        font-size: 15px;
        line-height: 18px; } }
    .catalog-card__sub a:not(:last-child) {
      margin: 0 0 12px; }
    .catalog-card__sub a:hover {
      color: #1AC5F4; }

.header {
  position: relative;
  z-index: 10;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09); }
  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
  .header-part {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .header-part--fit {
      flex: 1 0 auto;
      padding-left: 50px; }
  .header-top {
    background-color: #EFF4FA;
    padding: 10px 0; }
  .header-main {
    background-color: #fff; }

.header-logo svg {
  fill: #012472;
  width: 240px;
  height: 35px; }

.header-email {
  margin-right: 85px; }
  @media (max-width: 1199px) {
    .header-email {
      margin: 10px 0 0;
      text-align: center; } }
  .header-email a {
    font-size: 21px;
    line-height: 26px;
    color: #012472; }
    @media (max-width: 1199px) {
      .header-email a {
        font-size: 15px;
        line-height: 18px;
        margin: 0;
        font-weight: 600; } }
    .header-email a:hover {
      color: #1AC5F4; }

.header-contacts__item {
  margin-left: 45px; }
  .header-contacts__item:hover .header-contacts__value {
    color: #1AC5F4; }
  @media (max-width: 1199px) {
    .header-contacts__item {
      margin: 0;
      display: flex;
      flex-direction: column;
      text-align: center; } }

.header-contacts__title {
  font-size: 21px;
  line-height: 28px;
  color: #9A9BA8; }
  @media (max-width: 1199px) {
    .header-contacts__title {
      font-size: 15px;
      line-height: 18px;
      margin: 0 0 3px; } }

.header-contacts__value {
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  color: #012472;
  transition: color 0.3s;
  margin-left: 12px; }
  @media (max-width: 1199px) {
    .header-contacts__value {
      font-size: 15px;
      line-height: 18px;
      margin: 0; } }

.header-menu {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .header-menu__wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 -18px; }
  .header-menu__item a {
    display: block;
    padding: 35px 18px;
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    color: #121212; }
    .header-menu__item a:hover {
      color: #1AC5F4; }

.header-actions {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .header-actions__item:not(:first-child) {
    margin-left: 30px; }

.header-link {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center; }
  @media (max-width: 1199px) {
    .header-link {
      grid-template-columns: 30px 1fr; } }
  .header-link:hover .header-link__icon svg {
    fill: #1AC5F4; }
  .header-link:hover .header-link__name {
    color: #1AC5F4; }
  .header-link__label {
    background-color: #FF0033;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 3px;
    right: 10px;
    border-radius: 50%;
    font-size: 0;
    display: none; }
    .header-link__label:not(:empty) {
      display: block; }
    @media (max-width: 1199px) {
      .header-link__label {
        width: 8px;
        height: 8px; } }
  .header-link__icon {
    position: relative; }
    .header-link__icon svg {
      width: 30px;
      height: 30px;
      fill: #121212;
      transition: fill 0.3s; }
      @media (max-width: 1199px) {
        .header-link__icon svg {
          width: 22px;
          height: 22px; } }
  .header-link__name {
    font-size: 21px;
    line-height: 26px;
    color: #121212;
    transition: color 0.3s; }
    @media (max-width: 1199px) {
      .header-link__name {
        font-size: 16px;
        line-height: 18px; } }

#js-catalog-menu-close {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 2; }

.catalog-button {
  cursor: pointer;
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  margin-right: 36px; }
  .catalog-button:hover .catalog-button__name, .catalog-button.opened .catalog-button__name {
    color: #1AC5F4; }
  .catalog-button:hover .catalog-button__icon span, .catalog-button.opened .catalog-button__icon span {
    background-color: #1AC5F4; }
  .catalog-button.opened .catalog-button__icon span:nth-child(1) {
    top: 5px;
    transform: rotate(45deg); }
  .catalog-button.opened .catalog-button__icon span:nth-child(2) {
    opacity: 0; }
  .catalog-button.opened .catalog-button__icon span:nth-child(3) {
    bottom: 6.8px;
    transform: rotate(-45deg); }
  .catalog-button__icon {
    width: 18px;
    height: 14px;
    position: relative; }
    .catalog-button__icon span {
      display: block;
      position: absolute;
      background-color: #121212;
      width: 100%;
      height: 2px;
      border-radius: 5px;
      transition-duration: 0.3s; }
      .catalog-button__icon span:nth-child(1) {
        top: 0; }
      .catalog-button__icon span:nth-child(2) {
        top: calc(50% - 1px); }
      .catalog-button__icon span:nth-child(3) {
        bottom: 0; }
  .catalog-button__name {
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    color: #121212;
    transition: color 0.3s; }

.catalog-menu {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  transition-property: opacity, top;
  transition-duration: 0.3s;
  right: 0;
  left: 0; }
  .catalog-menu.opened {
    opacity: 1;
    visibility: visible;
    top: 100%; }
  .catalog-menu__container {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-column-gap: 30px;
    background-color: #FFFFFF;
    padding: 35px 30px;
    box-shadow: 0px 39px 83px rgba(0, 0, 0, 0.14); }
  .catalog-menu__wrap {
    display: none;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px; }
    .catalog-menu__wrap.opened {
      display: grid; }

.category-listblock {
  background-color: #EFF4FA;
  border-radius: 8px;
  padding: 28px 25px; }
  @media (max-width: 1199px) {
    .category-listblock {
      padding: 15px 20px; } }
  .category-listblock__haschild {
    padding-right: 30px;
    position: relative; }
    .category-listblock__haschild::after {
      content: '';
      display: block;
      border-top: 1px solid #012472;
      border-right: 1px solid #012472;
      width: 8px;
      height: 8px;
      transform: rotate(41deg);
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: border-color 0.3s; }
  .category-listblock__item {
    display: block;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #012472;
    transition: color 0.3s; }
    @media (max-width: 1199px) {
      .category-listblock__item {
        font-size: 16px;
        line-height: 22px; } }
    .category-listblock__item:hover, .category-listblock__item.opened {
      color: #1AC5F4; }
      .category-listblock__item:hover::after, .category-listblock__item.opened::after {
        border-color: #1AC5F4; }
    .category-listblock__item:not(:last-child) {
      margin: 0 0 16px; }
      @media (max-width: 1199px) {
        .category-listblock__item:not(:last-child) {
          margin: 0 0 10px; } }

.header-mobile {
  position: relative;
  z-index: 53;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px; }
  .header-mobile .header-logo svg {
    width: 160px;
    height: 22px; }

.header-burger {
  padding: 10px; }
  .header-burger__wrap {
    width: 20px;
    height: 16px;
    position: relative; }
  .header-burger span {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: #121212;
    transition: 0.3s;
    transition-property: top, bottom, opacity, transform; }
    .header-burger span:nth-child(1) {
      top: 0; }
    .header-burger span:nth-child(2) {
      top: 0;
      bottom: 0;
      margin: auto; }
    .header-burger span:nth-child(3) {
      bottom: 0; }
  .header-burger.opened span:nth-child(1) {
    top: calc(50% - 1px);
    transform: rotate(45deg); }
  .header-burger.opened span:nth-child(2) {
    opacity: 0; }
  .header-burger.opened span:nth-child(3) {
    bottom: calc(50% - 1px);
    transform: rotate(-45deg); }

.mobile-menu {
  position: fixed;
  z-index: 51;
  top: 64px;
  bottom: 0;
  left: 100%;
  width: 100%;
  background-color: #fff;
  overflow: scroll;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s; }
  .mobile-menu.opened {
    left: 0%; }
  .mobile-menu__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .mobile-menu__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #DFDFDF; }
  .mobile-menu__button {
    padding: 15px 0px; }
    .mobile-menu__button:first-child {
      border-right: 1px solid #DFDFDF; }
  .mobile-menu__main {
    padding: 15px 15px 20px; }
  .mobile-menu__nav {
    display: flex;
    flex-direction: column;
    padding: 0 0 35px; }
    .mobile-menu__nav-item {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #121212; }
      .mobile-menu__nav-item:not(:last-child) {
        margin: 0 0 16px; }
    .mobile-menu__nav-haschild {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .mobile-menu__nav-haschild::after {
        content: '';
        position: relative;
        display: block;
        width: 8px;
        height: 8px;
        border-top: 1px solid #121212;
        border-right: 1px solid #121212;
        transform: rotate(45deg); }
  .mobile-menu__search {
    padding: 0 0 20px; }
  .mobile-menu__contacts {
    padding: 20px 30px;
    background-color: #EFF4FA; }
    .mobile-menu__contacts .header-contacts {
      display: grid;
      grid-template-columns: 1fr 1fr; }

.footer {
  background-color: #040C20; }
  @media (max-width: 575px) {
    .footer {
      padding-bottom: 25px; } }

.footer-main {
  padding: 65px 0 70px; }
  @media (max-width: 1199px) {
    .footer-main {
      padding: 30px 0; } }
  .footer-main__container {
    display: grid;
    grid-template-columns: 30% 12% 32% 1fr;
    grid-column-gap: 95px; }
    @media (max-width: 1199px) {
      .footer-main__container {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 40px; } }
    @media (max-width: 991px) {
      .footer-main__container {
        grid-template-columns: 1fr; } }

.footer-catalog__title {
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  color: #FFFFFF;
  padding: 0 0 10px;
  margin: 0 0 15px;
  border-bottom: 1px solid #494E5E; }

.footer-catalog__categories {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 65px;
  grid-row-gap: 18px; }
  @media (max-width: 575px) {
    .footer-catalog__categories {
      grid-template-columns: 1fr; } }
  .footer-catalog__categories a {
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF; }
    .footer-catalog__categories a:hover {
      color: #1AC5F4; }

@media (max-width: 991px) {
  .footer-menu {
    padding: 24px 0 0;
    border-top: 1px solid #494E5E; } }

.footer-menu a {
  display: block;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  color: #FFFFFF; }
  .footer-menu a:not(:last-child) {
    margin: 0 0 24px; }
  .footer-menu a:hover {
    color: #1AC5F4; }

.footer-contacts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 40px; }
  @media (max-width: 767px) {
    .footer-contacts {
      grid-template-columns: 1fr;
      grid-row-gap: 40px; } }
  .footer-contacts__item-title {
    font-size: 21px;
    line-height: 26px;
    color: #9A9BA8; }
  .footer-contacts__item-value {
    display: block;
    font-size: 21px;
    line-height: 26px;
    color: #FFFFFF; }
    .footer-contacts__item-value:hover {
      color: #1AC5F4; }

.footer-social .icon:not(:last-child) {
  margin-right: 15px; }

.footer-address {
  padding: 20px 0 0;
  font-size: 21px;
  line-height: 26px;
  color: #FFFFFF; }

.footer-bottom {
  border-top: 1px solid #494E5E;
  padding: 18px 0; }
  @media (max-width: 991px) {
    .footer-bottom {
      padding: 30px 0; } }
  .footer-bottom__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .footer-bottom__container {
        flex-direction: column;
        align-items: flex-start; } }
  .footer-bottom__text {
    font-size: 18px;
    line-height: 24px;
    color: #9A9BA8;
    transition: color 0.3s; }

.footer-policy a:hover {
  color: #fff; }

@media (max-width: 991px) {
  .footer-policy {
    padding: 18px 0 10px; } }

.footer-ps {
  display: block; }
  .footer-ps svg {
    width: 145px;
    height: 30px;
    margin-left: 6px; }
  .footer-ps:hover .footer-bottom__text {
    color: #fff; }

.search-form {
  position: relative;
  background-color: #FFFFFF;
  border: 1.5px solid #C7C8CF;
  border-radius: 8px;
  padding: 15px 25px; }
  @media (max-width: 1199px) {
    .search-form {
      padding: 12px 15px; } }
  .search-form--header {
    width: 400px; }
  .search-form__field {
    border: none;
    font-size: 21px;
    line-height: 26px;
    color: #121212;
    width: 100%;
    height: 100%; }
    .search-form__field::placeholder {
      color: #9A9BA8; }
    @media (max-width: 1199px) {
      .search-form__field {
        font-size: 15px;
        line-height: 18px; } }
  .search-form__button {
    position: absolute;
    background-color: transparent;
    border: none;
    right: 20px;
    cursor: pointer; }
    @media (max-width: 1199px) {
      .search-form__button {
        right: 15px; } }
    .search-form__button svg {
      width: 28px;
      height: 28px;
      fill: #9A9BA8; }
      @media (max-width: 1199px) {
        .search-form__button svg {
          width: 22px;
          height: 22px; } }

.login-container {
  max-width: 475px;
  width: 100%; }
  @media (max-width: 991px) {
    .login-container {
      margin-bottom: 20px; } }

.order-check {
  display: block;
  font-size: 24px;
  margin-bottom: 40px; }

.login-ask {
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 25px;
  color: #121212; }
  @media (max-width: 1199px) {
    .login-ask {
      font-size: 14px;
      line-height: 16px;
      margin: 0 0 10px; } }
  .login-ask a {
    color: #121212;
    text-decoration: underline; }
    .login-ask a:hover {
      color: #1AC5F4; }

.login-block:not(:last-child) {
  margin: 0 0 30px; }
  @media (max-width: 1199px) {
    .login-block:not(:last-child) {
      margin: 0 0 15px; } }

.login-block__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 20px; }
  .login-block__title-name {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #121212; }

.login-cols {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .login-cols {
      flex-direction: column;
      align-items: center;
      margin-top: 50px; } }

.login-fake-tabs {
  border-bottom: 1px solid #C7C8CFCC;
  margin-bottom: 25px; }
  @media (max-width: 991px) {
    .login-fake-tabs {
      display: flex; } }
  .login-fake-tabs__item {
    display: inline-block;
    margin-bottom: -1px;
    font-size: 32px;
    line-height: 33px;
    font-weight: 700;
    color: #9A9BA8;
    padding: 0px 32px 12px;
    transition: color .3s ease; }
    @media (max-width: 991px) {
      .login-fake-tabs__item {
        width: 50%;
        display: flex;
        padding: 0px 0 10px;
        justify-content: center;
        font-size: 28px;
        line-height: 29px; } }
    @media (max-width: 575px) {
      .login-fake-tabs__item {
        font-size: 21px;
        line-height: 22px; } }
    .login-fake-tabs__item:hover {
      color: #121212; }
    .login-fake-tabs__item.active {
      color: #121212;
      border-bottom: 5px solid #FF0033; }

.login-password-reset {
  display: flex;
  justify-content: center;
  margin-top: 12px; }
  .login-password-reset a {
    color: #FF0033;
    font-size: 18px;
    line-height: 25.2px; }
    .login-password-reset a:hover {
      text-decoration: underline; }

.login-info {
  display: flex;
  flex-direction: column;
  max-width: 740px;
  margin-right: 30px; }
  @media (max-width: 991px) {
    .login-info {
      margin-right: 0px; } }
  .login-info span {
    font-size: 24px;
    line-height: 25.2px;
    color: #121212;
    font-weight: 700;
    margin-top: 34px;
    margin-bottom: 16px;
    margin-top: 18px; }
    @media (max-width: 991px) {
      .login-info span {
        font-size: 21px;
        line-height: 22px; } }
  .login-info p {
    font-size: 18px;
    line-height: 25px;
    color: #121212;
    margin-bottom: 16px; }
    @media (max-width: 991px) {
      .login-info p {
        font-size: 16px;
        line-height: 21px; } }
    @media (max-width: 575px) {
      .login-info p {
        font-size: 14px;
        line-height: 16px; } }
  .login-info a {
    color: #012472;
    text-decoration: underline; }
    .login-info a:hover {
      text-decoration: none; }
  .login-info ul {
    margin-bottom: 28px; }
    .login-info ul li {
      display: grid;
      grid-template-columns: 10px 1fr;
      column-gap: 15px;
      font-size: 18px;
      line-height: 25px;
      font-weight: 400;
      color: #121212;
      margin-bottom: 8px; }
      @media (max-width: 991px) {
        .login-info ul li {
          font-size: 16px;
          line-height: 21px;
          column-gap: 10px; } }
      @media (max-width: 575px) {
        .login-info ul li {
          font-size: 14px;
          line-height: 16px;
          column-gap: 5px; } }
      .login-info ul li span {
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: #121212;
        margin: 0; }
        @media (max-width: 991px) {
          .login-info ul li span {
            font-size: 16px;
            line-height: 21px; } }
        @media (max-width: 575px) {
          .login-info ul li span {
            font-size: 14px;
            line-height: 16px; } }
  .login-info .login-info__h1 {
    font-weight: 700;
    font-size: 32px;
    color: #121212;
    line-height: 33px;
    margin-bottom: 16px;
    margin-top: 0px; }
    @media (max-width: 991px) {
      .login-info .login-info__h1 {
        font-size: 28px;
        line-height: 29px; } }
    @media (max-width: 575px) {
      .login-info .login-info__h1 {
        font-size: 24px;
        line-height: 25px; } }
