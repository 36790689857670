@import 'base/app';


.login-container{
    max-width: 475px;
    width: 100%;
    @include _992{
        margin-bottom: 20px;
    }
}
.order-check{
    display: block;
    font-size: 24px;
    margin-bottom: 40px;
}

.login-ask{
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 25px;
    color: #121212;
    @include _1200{
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 10px;
    }
    a{
        color: #121212;
        text-decoration: underline;
        &:hover{
            color: $main-clr;
        }
    }
}

.login-form{

}

.login-block{
    &:not(:last-child){
        margin: 0 0 30px;
        @include _1200{
            margin: 0 0 15px;
        }
    }
    &__title{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 20px;
        &-name{
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: #121212;
        }
    }
}
.login-cols{
    display: flex;
    justify-content: space-between;
    @include _992{
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }
}
.login-fake-tabs{
    border-bottom: 1px solid #C7C8CFCC;
    margin-bottom: 25px;
    @include _992{
        display: flex;
    }
    &__item{
        display: inline-block;
        margin-bottom: -1px;
        font-size: 32px;
        line-height: 33px;
        font-weight: 700;
        color: #9A9BA8;
        padding: 0px 32px 12px;
        transition: color .3s ease;
        @include _992{
            width: 50%;
            display: flex;
            padding: 0px 0 10px;
            justify-content: center;
            font-size: 28px;
            line-height: 29px;
        }
        @include _576{
            font-size: 21px;
            line-height: 22px;
        }
        &:hover{
            color: #121212;
        }
        &.active{
            color: #121212;
            border-bottom: 5px solid #FF0033;
        }
    }
}
.login-password-reset{
    display: flex;
    justify-content: center;
    margin-top: 12px;
    a{
        color: #FF0033;
        font-size: 18px;
        line-height: 25.2px;
        &:hover{
            text-decoration: underline;
        }
    }
}
.login-info{
    display: flex;
    flex-direction: column;
    max-width: 740px;
    margin-right: 30px;
    @include _992{
        margin-right: 0px;
    }
    span{
        font-size: 24px;
        line-height: 25.2px;
        color: #121212;
        font-weight: 700;
        margin-top: 34px;
        margin-bottom: 16px;
        margin-top: 18px;
        @include _992{
            font-size: 21px;
            line-height: 22px;
        }
    }
    p{
        font-size: 18px;
        line-height: 25px;
        color: #121212;
        margin-bottom: 16px;
        @include _992{
            font-size: 16px;
            line-height: 21px;
        }
        @include _576{
            font-size: 14px;
            line-height: 16px;
        }
    }
    a{
        color: #012472;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
    ul{
        margin-bottom: 28px;
        li{
            display: grid;
            grid-template-columns: 10px 1fr;
            column-gap: 15px;
            font-size: 18px;
            line-height: 25px;
            font-weight: 400;
            color: #121212;
            margin-bottom: 8px;
            @include _992{
                font-size: 16px;
                line-height: 21px;
                column-gap: 10px;
            }
            @include _576{
                font-size: 14px;
                line-height: 16px;
                column-gap: 5px;
            }
            span{
                font-size: 18px;
                line-height: 25px;
                font-weight: 400;
                color: #121212;
                margin: 0;
                @include _992{
                    font-size: 16px;
                    line-height: 21px;
                }
                @include _576{
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    }
    .login-info__h1{
        font-weight: 700;
        font-size: 32px;
        color: #121212;
        line-height: 33px;
        margin-bottom: 16px;
        margin-top: 0px;
        @include _992{
            font-size: 28px;
            line-height: 29px;
        }
        @include _576{
            font-size: 24px;
            line-height: 25px;
        }
    }
}
